import { Icon } from 'antd'
import { IconProps } from 'antd/lib/icon'
import { Dispatch } from 'easy-peasy'
import React from 'react'
import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap'
import { FaLock, FaSignOutAlt } from 'react-icons/fa'
import { MdLanguage } from 'react-icons/md'
import { connect } from 'react-redux'
import { RouteChildrenProps } from 'react-router'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AppRoutes, getRoute } from '../AppRoutes'
import { graphTranslations } from '../Diagrams/graphTranslations'
import languageManager, { Language } from '../LanguageManager'
import { IApplicationModel } from '../store/store'
import polyglot from '../Translator'
import './Navbar.scss'
import RedoxLogo from '../RedoxLogo'

const MenuIcon = styled<IconProps>(props => <Icon {...props} />)`
  font-size: 18px;
  padding-right: 8px;
`

const EarthIcon = styled(MdLanguage)`
  margin-right: 3px;
`

function RedoxNavbar({ userProfile, location, signOut, setLanguage }: INavbarProps & RouteChildrenProps) {
  return (
    !location.pathname.includes(AppRoutes.FILL_DIAGRAMS) && (
      <Navbar id="navbar" collapseOnSelect fluid fixedTop>
        <Navbar.Header>
          <Link to={getRoute(AppRoutes.HOME)}>
            <RedoxLogo className="logo" />
          </Link>
          <Navbar.Toggle className="toggle" />
        </Navbar.Header>
        <Navbar.Collapse>
          {!userProfile && (
            <Nav>
              <LinkContainer to={getRoute(AppRoutes.ABOUT)}>
                <NavItem>{polyglot.t('About')}</NavItem>
              </LinkContainer>

              <LinkContainer to={getRoute(AppRoutes.PRICING)}>
                <NavItem>{polyglot.t('pricingProduct')}</NavItem>
              </LinkContainer>
              {/* <LinkContainer to={getRoute(AppRoutes.TUTORIALS)}>
                <NavItem>{polyglot.tr(tutorialsTranslations.tutorial)}</NavItem>
              </LinkContainer> */}
            </Nav>
          )}
          {userProfile && (
            <Nav>
              <LinkContainer to={getRoute(AppRoutes.LAW_FIRM)}>
                <NavItem>
                  <MenuIcon type="bank" /> {polyglot.tr(graphTranslations.firm)}
                </NavItem>
              </LinkContainer>
              <LinkContainer to={getRoute(AppRoutes.MY_DIAGRAMS)}>
                <NavItem>
                  <MenuIcon type="folder-open" />
                  {polyglot.tr(graphTranslations.charts)}
                </NavItem>
              </LinkContainer>
              <LinkContainer to={getRoute(AppRoutes.FILL_NEW_DIAGRAM)}>
                <NavItem>
                  <MenuIcon type="plus" /> {polyglot.tr(graphTranslations.new)}
                </NavItem>
              </LinkContainer>
            </Nav>
          )}

          <Nav pullRight>
            {!userProfile && (
              <LinkContainer to={getRoute(AppRoutes.LOGIN)}>
                <NavItem>
                  <FaLock /> {polyglot.t('signIn')}
                </NavItem>
              </LinkContainer>
            )}

            {userProfile && (
              <NavDropdown
                title={
                  <span>
                    <img className="user-image" alt="" src={userProfile.picture} />
                    {userProfile.name}
                  </span>
                }
                id="user-nav-dropdown"
              >
                <MenuItem className="pointer-events-none">
                  <div>{polyglot.t('signedInAs')}</div>
                  <div className="boldish">{userProfile.email}</div>
                </MenuItem>
                <MenuItem divider />
                <MenuItem onClick={signOut}>
                  <FaSignOutAlt /> {polyglot.t('signOut')}
                </MenuItem>
              </NavDropdown>
            )}

            <LinkContainer to={languageManager.getOppositeLanguageUrl()}>
              <NavItem
                onClick={() => {
                  setLanguage(languageManager.getOppositeLanguage())
                }}
              >
                <EarthIcon size={14} />
                {polyglot.t('oppositeLanguage')}
              </NavItem>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  )
}

interface IStateProps {
  userProfile: {
    name: string
    picture: string
    email: string
  }
}

interface IDispatchProps {
  signOut: () => void
  setLanguage: (lang: Language) => void
}

type INavbarProps = IStateProps & IDispatchProps

const mapStateToProps = (state): IStateProps => ({
  userProfile: state.user.user
})

const mapDispatchToProps = (dispatch: Dispatch<IApplicationModel>): IDispatchProps => ({
  signOut: () => dispatch.user.signOut(),
  setLanguage: dispatch.language.setLanguage
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedoxNavbar)
